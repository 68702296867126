import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FaSearch } from "react-icons/fa";

const DivMargin = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  height: 57px;
  padding-left: 40px;
  border: 1px solid #e7e7e7;
  color: #e7e7e7;
  font-size: 14px;

  &::placeholder {
    padding-left: 0;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #BED731;
`;

const SearchBar = ({ searchTerm, setSearchTerm }) => {
  const { t } = useTranslation();

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <DivMargin>
      <IconWrapper>
        <FaSearch size={18} />
      </IconWrapper>
      <Input
        className="form-control"
        onChange={handleChange}
        placeholder={t('home.filter_msg')}
        value={searchTerm}
      />
    </DivMargin>
  );
};

export default SearchBar;