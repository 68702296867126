import { useParams } from 'react-router-dom';

import {
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { apiCall } from 'crud/api.crud';
import useGetUserId from 'hooks/api/useGetUserId';

import { Quiz as QuizType } from 'interfaces/guide.interface';

import { Controller, useForm } from 'react-hook-form';
import { useRef, useState } from 'react';
import { QuizResult } from 'modules/classroom/components/quiz/Result';
import { useGuideContext } from 'modules/classroom/context/GuideContext';
import { useTranslation } from 'react-i18next';
import { Field } from './Field';

type AnswerForm = Record<string, string[]>;

interface QuizProps {
  quiz: QuizType;
}

export function Quiz({ quiz }: QuizProps) {
  const { id: activityId } = useParams<{ id: string }>();
  const userId = useGetUserId();
  const toast = useToast();
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });
  const { updateQuiz } = useGuideContext();
  const [score, setScore] = useState(quiz.answer?.score);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    control,
    reset,
  } = useForm<AnswerForm>({
    defaultValues: {},
  });

  const cancelRef = useRef<HTMLButtonElement>(null);
  const submitRef = useRef<HTMLButtonElement>(null);

  const { quiz_fields = [] } = quiz || {};

  if (!quiz_fields.length) return null;

  const onSubmit = async (data: AnswerForm) => {
    if (!isOpen) {
      onOpen();
      return;
    }

    const formattedAnswers = Object.entries(data).map(([key, value]) => {
      const field = quiz_fields.find((field) => field.id === parseInt(key));
      if (!field) return null;

      const options = field?.options.filter((option) => value.includes(String(option.id)));

      return {
        fieldId: field?.id,
        options: options?.map((option) => ({
          id: option.id,
          value: option.answer,
        })),
      };
    });

    try {
      const { data } = await apiCall(
        `activity/${activityId}/quiz`,
        { userId, activityId: Number(activityId), quizId: quiz.id, answers: formattedAnswers },
        'POST',
      );

      updateQuiz(quiz.id, { answer: { quiz_id: quiz.id, score: data.score, attempts: data.attempts } });
      setScore(data.score);
    } catch (error) {
      toast({
        title: 'Error',
        description: t('error.quiz'),
        status: 'error',
      });
    }
  };

  const handleReset = () => {
    reset();
    onClose();
    setScore(undefined);
  };

  if (typeof score === 'number') {
    return <QuizResult score={score} quiz={quiz} onReset={handleReset} />;
  }

  return (
    <Stack
      p={{
        base: 4,
        md: 8,
      }}
      spacing={8}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {quiz_fields.map(({ id, name, options }) => (
        <Controller
          control={control}
          key={id}
          name={String(id)}
          rules={{
            required: 'Este campo es requerido',
            minLength: 1,
          }}
          render={({ field: { onChange } }) => (
            <FormControl as="fieldset" isInvalid={!!errors[id]}>
              <FormLabel as="legend" fontSize="lg">
                {name}
              </FormLabel>
              <Field options={options} onChange={onChange} />
              <FormErrorMessage>{errors[id]?.message}</FormErrorMessage>
            </FormControl>
          )}
        />
      ))}
      <Button type="submit" alignSelf="flex-end" ref={submitRef} isDisabled={isSubmitting}>
        {t('quiz.finish')}
      </Button>
      <AlertDialog leastDestructiveRef={cancelRef} onClose={onClose} isOpen={isOpen} isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{t('quiz.alert')}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogFooter>
            <Button ref={cancelRef} type="button" onClick={onClose} colorScheme="gray" isDisabled={isSubmitting}>
              {t('check')}
            </Button>
            <Button
              ml={3}
              type="submit"
              onClick={() => {
                submitRef.current?.click();
              }}
              isLoading={isSubmitting}
            >
              {t('quiz.finish')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Stack>
  );
}
