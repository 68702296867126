import { Alert, AlertIcon, AlertTitle, Box, Button, Center, Heading, Stack, Text } from '@chakra-ui/react';

import config from 'config';
import { useMemo } from 'react';
import { NextLesson } from 'modules/classroom/components/NextLesson';
import { useTranslation } from 'react-i18next';
import { Quiz } from 'interfaces/guide.interface';
import { useGuideContext } from 'modules/classroom/context/GuideContext';

interface QuizResultProps {
  score: number;
  quiz: Quiz;
  onReset?: () => void;
}

export function QuizResult({ score, quiz, onReset }: QuizResultProps) {
  const { guide } = useGuideContext();
  const { t } = useTranslation('translation', { keyPrefix: 'classroom' });
  const isApproved = useMemo(() => score >= Number(config.classroom?.min_approved_score), [score]);
  const canReplay = useMemo(() => {
    if (isApproved) return true;
    if (!guide.max_attempts) return true;
    if (!quiz.answer) return true;
    return quiz.answer?.attempts < guide.max_attempts;
  }, [isApproved, guide, quiz]);

  const attemps = useMemo(() => {
    if (!guide.max_attempts) return 0;
    if (!quiz.answer) return 0;
    return guide?.max_attempts - quiz.answer.attempts;
  }, [guide, quiz]);

  return (
    <Center minH="75vh">
      <Stack spacing={8} textAlign="center" align="center">
        <Heading size="2xl">{t(`quiz.${isApproved ? 'approved' : 'disapproved'}.title`)}</Heading>
        <Text fontWeight="semibold" fontSize="xl">
          {t(`quiz.${isApproved ? 'approved' : 'disapproved'}.description`)}
        </Text>
        <Box py={12}>
          <Text fontWeight="semibold" fontSize="3xl">
            {t('result')}:
            <Text as="span" color={isApproved ? 'green.500' : 'red.500'} ml={2}>
              {score.toFixed()}%
            </Text>
          </Text>
        </Box>
        {guide.max_attempts && (
          <Alert status="warning" w="auto">
            <AlertIcon />
            <AlertTitle>{attemps > 0 ? t('quiz.attempts', { count: attemps }) : t('quiz.max_attempts')}</AlertTitle>
          </Alert>
        )}

        {!isApproved && onReset && canReplay && <Button onClick={onReset}>{t('quiz.replay')}</Button>}
        {isApproved && <NextLesson>{t('quiz.next')}</NextLesson>}
      </Stack>
    </Center>
  );
}
