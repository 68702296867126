import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';
import Routing from './Routing';
import config from './config';
import GlobalCSS from './theme';
import './App.scss';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ChatWidget from 'components/ChatWidget';


Sentry.init({
  dsn: 'https://50b0ab0f49ef4f5a842921e520ce4198@o4504872332820480.ingest.sentry.io/4504884827848704',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production',
});

function App({ store }) {
  // if (process.env.NODE_ENV === 'development') {
  // console.log('estamos activo');
  //   const { worker } = require('./crud/Mocks/Router/handlers.js')
  //   worker.start()
  // }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={config.icon} />
        <title>{config.title}</title>
      </Helmet>
      <Provider store={store}>
        <GlobalCSS />
        <Routing />
      { config.chatWidget && <ChatWidget  clientId={Number(process.env.REACT_APP_ID_ENTITY)} />}
      </Provider>
    </>
  );
}

export default App;
