import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsChatLeftText } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import config from '../../../../config';
import { apiCall } from '../../../../crud/api.crud';
import useGetUserId from '../../../../hooks/api/useGetUserId';
import { useBanners } from '../../../../hooks/useBanners';
import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import useStore from '../../../../store/zustand/store';
import { invercionMatriz } from '../../../../utils/helper';
import HomeReactour from '../../../main/components/Reactour/HomeReactour';
import { Map } from '../../../map/Map';
import ActivitiesCard from '../../../molecules/ActivitiesCard';
import Card from '../../../molecules/Card';
import Loading from '../../../molecules/Loading';
import Pagination from '../../../molecules/Pagination';
import TutorialsCard from '../../../molecules/TutorialsCard';
import VolunteerExperiences from '../../../molecules/VolunteerExperiences';
import Conditional from '../../../shared/Conditional';
import './cardContainer.scss';
import FilterColumn from './components/FilterColumn';
import FilterModal from './components/FilterModal';
import Options from './components/Options';
import SimpleSlider from './components/SimpleSlider';

import { FaFilter } from 'react-icons/fa';
import { ButtonPrimary } from '../../../molecules/Button';
import { isBaNorte } from 'utils/contants';
import SearchBar from './components/SearchBar';

const DivBanners = styled('div')`
  img {
    height: 200px;
  }
  &.globant {
    width: 90%;
    margin: 30px auto;
  }
`;

const ChatButton = styled.button`
  position: fixed;
  left: 30px;
  bottom: 20px;
  background-color: ${(props) => (props.isBaNorte  ? "#E8002A" : "#36a3f7")};
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  &:hover {
   background-color: 
     ${(props) => (props.isBaNorte  ? "#9c001c" : "#007bb5")}; 
  }
  opacity: 0.8;
`;

const converFilterToUrl = (filters) => {
  let onlyValues = '';
  let customValues = '';
  for (const filter in filters) {
    const filterData = filters[filter];
    if (!filterData) continue;

    if (filter.substring(0, 14) == 'inCustomFilter') {
      customValues += (customValues.length == 0 ? '' : ',') + filterData.map((v) => v.value).join(';');
      continue;
    }

    onlyValues += `&filter[${filter}]=${
      Array.isArray(filterData) ? filterData.map((v) => v.value).join(';') : filters[filter]
    },`;
  }

  return onlyValues + `&filter[inCustomFilter]=${customValues}`;
};

const CardsContainer = ({ isOpenTour }) => {
  const user = useStore((state) => state.user);
  const { t, i18n } = useTranslation();

  const userId = useGetUserId();
  const [activities, setActivities] = useState(null);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState({});
  const [tutorials, setTutorials] = useState(null);
  const [userMarkers, setUserMarkers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const AppBanners = useBanners();

  useEffect(() => {
    const pageApi = page ? `page[number]=${page}` : '';
    apiCall(
      `activity/allwithuser?${pageApi}&filter[entity_origin_id]=${process.env.REACT_APP_ID_ENTITY}${converFilterToUrl(
        filter,
      )}&filter[view_activities.status]=1,2&include=locations,form.fields.options${userId ? '&userId=' + userId : ''}`,
      null,
      'GET',
    ).then((response) => setActivities(response.data));
  }, [page, filter, i18n.language]);

  useEffect(() => {
    apiCall(
      `tutorials?filter[entity_id]=${process.env.REACT_APP_ID_ENTITY}${converFilterToUrl(filter)}`,
      null,
      'GET',
    ).then((response) => setTutorials(response.data.tutorials));
  }, [filter]);

  useEffect(() => {
    const getData = async () => {
      const { data: users } = await apiCall(`entity/${process.env.REACT_APP_ID_ENTITY}/users`, null, 'GET');

      const markers = users.data.reduce((acc, user) => {
        const address = user.addresses.length ? user.addresses[0] : null;

        if (!address) return acc;

        return [
          ...acc,
          {
            address: address.address,
            location_lat: Number(address.location_lat).toFixed(3),
            location_lng: Number(address.location_lng).toFixed(3),
            id: user.id,
            title: user.name,
          },
        ];
      }, []);

      setUserMarkers(markers);
    };

    if (config.users_in_map) {
      getData();
    }
  }, []);

  const colums = useBreakpoint() == 1 ? 1 : 2;
  if (!activities) return <Loading />;
  if (!tutorials) return <Loading />;

  const filteredActivities = activities.data.filter(activity =>
    activity.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const activitiesColumns = invercionMatriz(filteredActivities, colums + (tutorials.length ? 0 : 1));

  const now = moment().subtract(1, 'days');

  return (
    <>
      <Conditional condition={user}>{isOpenTour && <HomeReactour hour={config.has_hours} />}</Conditional>
      <FilterModal setIsOpen={setIsOpen} isOpen={isOpen} setFilter={setFilter} filter={filter} />
      <div
        style={config.profile_globant ? { display: 'inline' } : {}}
        className={config.profile_globant ? '' : 'container-fluid mt-4'}
      >
        {config.map_top && (
          <div className="card-deck">
            {config.banner ? (
              <div className="card">
                <img src={config.banner} alt="banner" referrerPolicy="no-referrer" />
              </div>
            ) : (
              !config.map_hide && (
                <>
                  <Card
                    description={<Map activities={activities.data} users={userMarkers} />}
                    title={t('home.home_msg')}
                    style={{ flexGrow: 200 }}
                  />
                  <VolunteerExperiences />
                </>
              )
            )}
          </div>
        )}
        {config.bannerODS && (
          <a
            href="https://www1.undp.org/content/undp/es/home/sustainable-development-goals.html"
            target="_blank"
            rel="noopener noreferrer"
            className="card"
            style={{ marginTop: '25px' }}
          >
            <img
              src={config.bannerODS}
              alt="banner"
              referrerPolicy="no-referrer"
              style={{
                maxWidth: '60%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </a>
        )}
        <DivBanners className={config.profile_globant ? 'globant' : ''}>
          {!!AppBanners.length && <SimpleSlider items={AppBanners} />}
        </DivBanners>
        {config.profile_globant ? (
          <div className={`flex d-xl-none align-items-end pr-4 pr-sm-2`}>
            <ButtonPrimary onClick={() => setIsOpen(!isOpen)} className="filter-reactour" style={{ marginBottom: '10px' }}>
              <FaFilter />
              {t('home.home_msg2')}
            </ButtonPrimary>
          </div>
        ) : (
          <Options setIsOpen={setIsOpen} setPage={setPage} meta={activities.meta} />
        )}
        {config.profile_globant ? (
          <div className="d-flex mb-3">
            <div className="d-none d-xl-block" style={{ flex: '1 0 25%' }}>
              <FilterColumn setFilter={setFilter} filter={filter} />
            </div>
            <div style={{ flex: '3 0 75%', marginLeft: '20px' }}>
              <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
              <div className={`card__container d-flex flex-wrap`}>
                {activitiesColumns.map((activitiesColumn, index) => (
                  <div
                    className={'globant__card__container__columns'}
                    key={`cardActivityColumn${index}`}
                    style={{ flex: '1 0 30%' }}
                  >
                    {activitiesColumn
                    .filter((activity) => now <= moment(activity.deadline) || activity.continuous_activity)
                    .map((activity) => (
                      <ActivitiesCard key={`cardActivity${activity.id}`} activity={activity} />
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={`card__container ${config && 'align-items-center align-items-md-stretch'}`}>
            <FilterColumn setFilter={setFilter} filter={filter} />
            {tutorials.length == 0 ? null : (
              <div className={'card__container__columns'}>
                {tutorials.map((tutorial) => (
                  <TutorialsCard key={`cardTutorial${tutorial.id}`} tutorial={tutorial}></TutorialsCard>
                ))}
              </div>
            )}
            {activitiesColumns.map((activitiesColumn, index) => (
              <div
                className={'card__container__columns'}
                key={`cardActivityColumn${index}`}
              >
                {activitiesColumn.map((activity) => (
                   <ActivitiesCard key={`cardActivity${activity.id}`} activity={activity} />
                ))}
              </div>
            ))}
          </div>
        )}
        {config.profile_globant && (
          <div className="d-flex justify-content-center" style={{ paddingBottom: '20px' }}>
            <Pagination meta={activities.meta} action={setPage} withRedux={false} />
          </div>
        )}
        {config.map_bottom && (
          <div className="card-deck mb-4">
            {config.banner ? (
              <div className="card">
                <img src={config.banner} alt="banner" />
              </div>
            ) : (
              <>
                <Card
                  description={<Map activities={activities.data} users={userMarkers} />}
                  title={t('home.home_msg')}
                  style={{ flexGrow: 200 }}
                />
                <VolunteerExperiences />
              </>
            )}
          </div>
        )}
      </div>
      {user && !config.profile_globant && (
        <Link to="/chat">
          <ChatButton isBaNorte={isBaNorte}>
            <BsChatLeftText size={24} />
          </ChatButton>
        </Link>
      )}
    </>
  );
};

export default CardsContainer;
