import React, { useEffect } from 'react';

interface ChatWidgetProps {
  clientId: number;
}

const ChatWidget: React.FC<ChatWidgetProps> = ({ clientId }) => {
  useEffect(() => {
    if (document.getElementById('tawk-script')) return;

    const script = document.createElement('script');
    script.id = 'tawk-script';
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://embed.tawk.to/6778283849e2fd8dfe02214c/1igmlq7k0';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    document.body.appendChild(script);

    script.onload = () => {
      if (window.Tawk_API) {
        window.Tawk_API.visitor = {
          name: `Cliente ${clientId}`,
          email: `cliente${clientId}@ejemplo.com`,
        };
      }
    };

    return () => {
      const scriptElement = document.getElementById('tawk-script');
      if (scriptElement) {
        scriptElement.remove();
      }
      if (window.Tawk_API && window.Tawk_API.visitor) {
        delete window.Tawk_API.visitor;
      }
    };
  }, [clientId]);

  return null;
};

export default ChatWidget;
